<template>
  <div class="container-flex mx-auto">
    <div class="list-container">
      <!-- PlaybookItemLibrary -->
      <base-list :sort-by-prop="sortBy"
                 :sort-desc-prop="sortDesc"
                 :items="playbookItemLibrary"
                 :totalItems="totalItems"
                 :fields=getFields()
                 :slots="templates"
                 :loading="loading"
                 :asyncFilter="filterList"
                 :no-local-sort-prop="true"
                 @page-changed="handlePageChanged"
                 @sortingChanged="handleSortingChanged"
      >
        <template slot="title">
          <div class="title ml-1">
            {{ staticText.playbookItemsDisplayName }}
          </div>
        </template>

        <template slot="leftListActions">
          <label-search
            class="ml-2"
            :tags="allItemTags"
            :system-protected-tags="true"
            @filter="(selectedTags)=>filterPlaybookItemsByLabel(selectedTags)"
          ></label-search>
        </template>

        <template slot="listActions">
          <div class="ml-auto d-flex">
            <b-btn id="new-playbook-btn-202012301137"
                   class="m-1 px-4 d-flex align-items-center"
                   variant="primary"
                   @click="openCreationModal"
            >
              <img src="/img/icons/light-plus-icon.svg"
                   class="mr-1"
              />
              {{ staticText.newPlaybookItemBtn }}
            </b-btn>
          </div>
        </template>
        <template v-slot:name="data">
          <div class="playbook" :class="{'disable-div': !isItemEditable(data.cell)}" >
            <div class="break-word cursor-pointer" @click="openPlaybookModal(data.cell.id)">
              {{ data.cell.name }}
            </div>
            <base-tag-input
              :labels="data.cell.tags"
              :available-tags="[]"
              :item-identifier="data.index"
              :system-protected-tags="true"
            ></base-tag-input>
          </div>
        </template>

        <template v-slot:updated_at="data">
          <bao-date-time-display :value="data.cell.updated_at"></bao-date-time-display>
        </template>

        <template v-slot:created_by="data">
          {{ getCreatedBy(data.cell) }}
        </template>

        <template v-slot:actions="data">
          <div class="d-flex">
            <div :id="'edit-lib-itm-btn-202406271054-' + data.cell.id"
              class="d-flex">
              <b-btn variant="secondary"
                     class="action-button mr-1 d-flex align-items-center"
                     :disabled="!isItemEditable(data.cell)"
                     @click="openPlaybookModal(data.cell.id)"
              >
                <img src="@/assets/svgs/edit-icon.svg" height="16px" width="16px" svg-inline/>
              </b-btn>
            </div>
            <b-tooltip
              v-if="!isItemEditable(data.cell)"
              :target="'edit-lib-itm-btn-202406271054-' + data.cell.id" triggers="hover"
              custom-class="custom-tooltip"
            >
              <div class="d-flex align-items-center">
                {{ staticText.disableEditBtnMessage}}
              </div>
            </b-tooltip>
            <div :id="'delete-lib-item-202406061046-' + data.cell.id">

              <bao-delete-button
                :id="'del-lib-item-btn-202406061045' + data.cell.id"
                variant="secondary"
                :disabled="!isItemDeletable(data.cell)"
                :url="'/api/libraryitems/'+data.cell.id"
                @deleted="getPlaybookItems"
              >
              </bao-delete-button>
            </div>
            <b-tooltip
              v-if="!isItemDeletable(data.cell)"
              :target="'delete-lib-item-202406061046-'+data.cell.id" triggers="hover"
              custom-class="custom-tooltip"
            >
              <div class="d-flex align-items-center">
                {{  disableItemDeletetionText(data.cell)}}
              </div>
            </b-tooltip>
          </div>
        </template>
      </base-list>
    </div>

    <!-- Playbook creation -->
    <!-- Playbook Name Modal -->
    <b-modal id="new-playbook-202012301137"
             v-model="showPlaybookCreationModal"
             body-class="p-1 modal-body--custom"
             centered
             size="xl"
             hide-footer
             hide-header
             no-close-on-esc
             no-close-on-backdrop
    >
      <!-- Global Error/Info display -->
      <div id="global-info-div"
           v-if="!!error"
           class="text-center sticky-alert"
      >
        <b-alert id="global-error-202117101745"
                 :show="!!error"
                 variant="danger"
                 dismissible
                 @dismissed="resetGlobalError"
        >
          <span v-html="error"></span>
        </b-alert>
      </div>
      <playbook-item
        :base-url="baseUrl"
        :is-open-from-library-items-modal="true"
        :item-types="playbookItemTypes()"
        :value="currentItem"
        :can-be-edited="currentUserIsAdminOrSuperuser"
        @handle-playbook-item-changed="(item) => currentItem = item"
        @get-selected-object="getSelectedObject"
        @emit-error="emitError"
      >
        <template #close-btn>
          <img
            class="closeBtn px-0 py-0 ml-2 cursor-pointer"
            src="/img/icons/close-icon.svg"
            @click.stop="cancelPlaybookCreation"
          />
        </template>
      </playbook-item>

      <div class="d-flex mt-3 px-4">
        <div class="mr-auto">
          <div v-if="currentItemParents.length > 0">
            <p class="text-slate mb-2">
              <b>{{ staticText.itemLibraryParentsLabel }}</b>
            </p>
            <ul class="text-slate-80 px-2">
              <li v-for="parent in currentItemParents" :key="parent.id">
                <router-link class="break-word px-2 pt-2 pb-1 mr-auto my-1" target="_blank"
                             :to="'/templates/edit/' + parent.id">
                  <i class="fas fa-random fa-sm mr-1"/>
                  {{ parent.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="text-slate mb-2 mt-3">
              {{ staticText.itemNotUsed }}
            </p>
          </div>
        </div>

        <div class="align-self-start">
          <b-btn id="modal-cancel-btn-202103051221"
                 variant="secondary"
                 class="px-4 mr-3"
                 @click="cancelPlaybookCreation"
          >
            {{ staticText.cancel }}
          </b-btn>

          <b-btn id="modal-save-btn-202103051221"
                 variant="primary"
                 class="px-4"
                 @click="validateAndSaveItem"
          >
            {{ staticText.save }}
          </b-btn>
        </div>

      </div>
    </b-modal>

  </div>
</template>

<script>
import BaseList from "../base/BaseList"
import appInfo from "@/apps/talkscript/index"
import axios from "axios"
import { playbookServices } from "./PlaybookServices"
import BaoDateTimeDisplay from "@/apps/base/BaoDateTimeDisplay"
import PlaybookItem from "./PlaybookItem"
import PlaybookItemMixin from "./PlaybookItemMixin"
import { mapActions, mapGetters } from "vuex"
import BaseTagInput from "@/apps/base/BaseTagInput"
import LabelSearch from "@/apps/talkscript/components/LabelSearch"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import { HUBSPOT_CALL_OUTCOME_ITEM_TAG } from '@/apps/dashboard/chartUtils'

export default {
  name: "PlaybookItemLibrary",
  mixins: [PlaybookItemMixin],
  components: {
    LabelSearch,
    BaseList,
    BaoDateTimeDisplay,
    PlaybookItem,
    BaseTagInput,
    BaoDeleteButton
  },
  data () {
    return {
      axios,
      appInfo,
      playbookItemLibrary: [],
      allItemTags: [],
      selectedLabels: [],
      sortBy: "updated_at",
      sortDesc: true,
      currentItem: {},
      currentItemParents: [],
      templates: [
        { name: "name", field: "name" },
        { name: "created_by", field: "created_by" },
        { name: "actions", field: "actions" },
        { name: "updated_at", field: "updated_at" }
      ],
      totalItems: null,
      loading: false,
      error: null,
      staticTextDefault: {
        newPlaybookItemBtn: "New Item",
        cancel: "Cancel",
        save: "Save",
        playbookModalTitle: "Playbook Library Item",
        NameLabel: "Name",
        itemTypeLabel: "Item Type",
        modifiedByLabel: "Modified",
        createdByLabel: "Created By",
        actionsLabel: "Actions",
        playbookItemsDisplayName: "Playbook Item Library",
        itemLibraryParentsLabel: "Playbooks, to which this item is added:",
        itemNotUsed: "This item is currently not added to any guide.",
        itemUsedInPlaybookMsg: "This item cannot be deleted because it is still added to at least one playbook.",
        itemGeneratedBySystemMsg: "This library item cannot be deleted because it is generated by the system.",
        disableEditBtnMessage: "This library item cannot be edited because it is generated by the system."
      },
      showPlaybookCreationModal: false
    }
  },
  watch: {
    showPlaybookCreationModal (val) {
      if (!val) {
        this.currentItem = {}
        this.currentItemParents = []
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUserIsAdminOrSuperuser: "auth/isAdminOrSuperUser"
    }),
    baseUrl () {
      return appInfo.apiUrl.libraryItems
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  async mounted () {
    await this.getPlaybookItems()
    this.setAvailableTags()
    this.getCRMObjectLink()
    if (this.$route.query.libraryItem) {
      this.openPlaybookModal(this.$route.query.libraryItem)
      this.$router.replace({ query: {} }) // This is to remove the query from the $route object
    }
  },
  methods: {
    ...playbookServices.methods,
    ...mapActions({
      getCRMObjectLink: "crmStore/getCRMObjectLink",
      retrieveFieldDefinitions: "crmStore/retrieveFieldDefinitions"
    }),
    isItemEditable(item){
      // hubspot call outcome items shouldn't be edited
      if (item.tags.includes(HUBSPOT_CALL_OUTCOME_ITEM_TAG)){
        return false
      } else {
        return true
      }
    },
    disableItemDeletetionText (item) {
      if (this.isItemGeneratedBySystem(item)) {
        return this.staticText.itemGeneratedBySystemMsg
      } else if (item.total_active_parents > 0) {
        return this.staticText.itemUsedInPlaybookMsg
      } else {
        return ""
      }
    },
    isItemDeletable (item) {
      /*
      check whether the item is deletable or not. conditions for this is -
        there shouldn't be any playbook using this item and
        it shouldn't be generated by system (created_by should be null)
      */
      return item.total_active_parents === 0 && !this.isItemGeneratedBySystem(item)
    },
    isItemGeneratedBySystem (item) {
      return !item.created_by || (Object.keys(item.created_by).length === 0 && item.created_by.constructor === Object)
    },
    filterPlaybookItemsByLabel (selectedLabels) {
      this.selectedLabels = selectedLabels
      this.getPlaybookItems()
    },
    filterList (value) {
      return this.$router.push({ query: { ...this.$route.query, name: value, currentPage: 1 } }).then(this.getPlaybookItems)
    },
    getSelectedObject (val) {
      this.getCRMObjectLink()
      this.retrieveFieldDefinitions([val])
    },
    checkLinkedFields (item) {
      if (item.linked_field && item.linked_field.crm_object_link && item.item_type === "crmlink") {
        this.retrieveFieldDefinitions([item.linked_field.crm_object_link])
      }
    },
    cancelPlaybookCreation () {
      this.showPlaybookCreationModal = false
      this.resetGlobalError()
    },
    async openCreationModal () {
      const defaultPlaybookItem = this.getDefaultPlaybookItem()
      this.currentItem = defaultPlaybookItem
      this.showPlaybookCreationModal = true
    },
    async openPlaybookModal (id) {
      const { data } = await this.axios.get(`${this.baseUrl}/${id}`)
      this.currentItem = data
      this.checkLinkedFields(this.currentItem)
      await this.getItemParents(this.currentItem.id)
      this.showPlaybookCreationModal = true
    },
    async getItemParents (itemId) {
      const { data } = await this.axios.get(`/api/talkscripts?main_container__children=${itemId}`)
      this.currentItemParents = data.results
    },
    async validateAndSaveItem () {
      const item = this.currentItem
      /**
       * This method checks if the item is valid and then sends the request to backend
       * itemPosition is required to show the position of the item in global error.
       **/
      return new Promise((resolve, reject) => {
        const error = this.validatePlaybookItem(item)
        if (error) {
          this.setGlobalError(item)
          reject(error)
          return
        }
        this.saveAndUpdatePlaybookItem(item, { url: this.baseUrl }).then(() => {
          this.getPlaybookItems()
          this.showPlaybookCreationModal = false
          resolve()
        }).catch(error => {
          this.setItemAndGlobalError(item, this.extractErrorMessage(error))
          reject(error)
        })
      })
    },
    handlePageChanged (pageNumber) {
      this.$router.push({ query: { ...this.$route.query, currentPage: pageNumber } })
      this.getPlaybookItems()
    },
    handleSortingChanged (newSort, sortDirection) {
      if (this.sortBy === newSort && this.sortDesc === sortDirection) {
        return
      }
      this.sortBy = newSort
      this.sortDesc = sortDirection
      this.getPlaybookItems()
    },
    async getPlaybookItems () {
      const params = {}
      this.playbookItemLibrary = []
      params.page = this.$route.query.currentPage ? this.$route.query.currentPage : 1
      params.page_size = this.$route.query.page_size ? this.$route.query.page_size : 10
      params.ordering = (this.sortDesc ? "-" : "") + this.sortBy

      if (this.selectedLabels.length > 0) {
        params.tags__name__in = this.selectedLabels.toString()
      }

      if (this.$route.query.name && this.$route.query.name.length > 0) params.name__icontains = this.$route.query.name

      this.loading = true
      const { data } = await this.axios.get(this.baseUrl, { params })
      this.playbookItemLibrary = data.results
      this.totalItems = data.count
      this.loading = false
    },
    setAvailableTags () {
      const tags = []
      this.playbookItemLibrary.forEach(
        playbookItem => tags.push(...playbookItem.tags)
      )
      this.allItemTags = new Array(...new Set(tags))
    },
    getCreatedBy (item) {
      return item.created_by ? item.created_by.first_name + " " + item.created_by.last_name : "System"
    },
    getFields () {
      return [
        { key: "name", label: this.staticText.NameLabel, sortable: true, sortDirection: "desc" },
        { key: "updated_at", label: this.staticText.modifiedByLabel, sortable: true },
        { key: "created_by", label: this.staticText.createdByLabel, sortable: true },
        { key: "actions", label: this.staticText.actionsLabel }
      ]
    },
    emitError (error, item) {
      const errorData = {
        message: error,
        item,
        itemLink: item.uniqueId
      }
      const errorMsg = this.extractErrorMessage(errorData.message)
      if (!errorMsg) {
        // If error is null and item is passed then reset error on global and item-level
        this.resetItemError(errorData.item)
        this.resetGlobalError()
      } else {
        // If error exists and item is passed then set error on global-level(with link to erroneous-item) and item-level
        this.setItemAndGlobalError(errorData.item, errorMsg)
      }
    },
    resetGlobalError () {
      this.error = null
    },
    setItemAndGlobalError (item, error) {
      this.setItemError(item, error)
      this.setGlobalError(item)
    },
    setGlobalError (item, itemPosition = 1) {
      const errorDetails = [{ position: itemPosition, itemLink: item.uniqueId }]
      const errorMsg = this.getGlobalErrorMsg(errorDetails, false)
      this.showError(errorMsg, item.is_objection)
    },
    showError (error) {
      this.error = error
    }
  }
}
</script>

<style scoped lang="scss">

li {
  list-style: none;
  margin-bottom: 6px;
}

li:last-child {
  margin-bottom: 0;
}

li a {
  text-decoration: none;
  margin: 6px 0;
  transition: 0.1s all ease-in;
  &:hover {
    cursor: pointer;
    background-color: $slate06;
    color: $slate;
    border: none;
    border-radius: 6px;
  }
}

.closeBtn {
  background: transparent;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

:deep(.modal-body--custom) {
  background: $background;
  border-radius: inherit;
}

.custom-tooltip{
  :deep(.tooltip-inner){
    padding: 0 !important;
  }
  :deep(.tooltip .tooltip-inner){
    padding: 0 !important;
  }
}

.disable-div{
  pointer-events: none
}
</style>
